<template>
    <v-dialog
      v-model="dialog"
      max-width="275px"
      :persistent="load"
    >
      <v-card :disabled="load">
        <v-card-title>
          {{ p_nuevo ? 'Nuevo tamaño' : 'Editar tamaño' }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="dialog = false"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="form">
            <v-row class="pt-8">
              <v-col cols="12" class="py-0">
                <v-text-field
                  v-model="tamanio.nombre"
                  label="Nombre"
                  validate-on-blur
                  autofocus
                  outlined
                  dense
                  @change="setAbreviacion()"
                ></v-text-field>
              </v-col>
							<!-- <v-col cols="12" class="py-0">
                <v-text-field
                  v-model="tamanio.abreviacion"
                  label="Abreviación"
                  validate-on-blur
                  autofocus
                  outlined
                  dense
                ></v-text-field>
              </v-col> -->
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pb-4">
          <v-btn
            color="error"
            class="mr-2"
            :disabled="load"
            @click="dialog = false"
          >
            Cancelar
          </v-btn>
          <BtnConfirmar
            :loading="load"
            @action="guardar()"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  /**
   *  Modal para editar o crear un tamaño
   * 
   *  Notas: este componente emite acciones (@nueva, @editar) al padre caundo se crea o edita un tamaño (en caso de estar definidas)
   *         todos los props son obligatorios
   */
  import BtnConfirmar from '../util/BtnConfirmar'
  
  export default {
    name: 'Tamanio',
    data () {
      return {
        load: false,
        tamanio: {},
        /*rules: {
          required: value => !!value || 'Campo requerido',
        }*/
      }
    },
    props: {
      value: Boolean,
      p_nuevo: Boolean,  // para editar un tamaño enviar en false, para un tamaño nueva en true
      p_tamanio: Object    // en caso de ser nuevo enviar el objeto con los atributros en null desde el padre
      /**
       *  Ej de p_tamanio = {
                  codigo: null,
									abreviacion: null,
                  nombre: null
                }
       */
    },
    computed: {
      dialog: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      }
    },
    components: {
      BtnConfirmar
    },
    watch: {
      dialog (val) {
        if (val) {
          // crea una copia del prop p_tamanio para editarlo
          this.tamanio = JSON.parse(JSON.stringify(this.p_tamanio))
        } else this.$refs.form.resetValidation();
      }
    },
    methods: {
      setAbreviacion(){
        this.tamanio.abreviacion = this.tamanio.nombre.slice(0,3);
      },
      async guardar () {
        if (this.tamanio.nombre) {
					/*if(this.tamanio.abreviacion && this.tamanio.abreviacion.length != 3 && this.tamanio.abreviacion.length != 4){
						return this.$store.dispatch('show_snackbar', { text: 'La abreviacion debe ser de 3 o 4 letras.', color: 'info'})
					}*/
          this.load = true
          let res = {};
          //si no se cambio nada
					if (this.tamanio.nombre == this.p_tamanio.nombre && !this.p_nuevo) {
						this.dialog = false
						this.load = false
						return this.$store.dispatch('show_snackbar', {
							text: 'No se realizaron cambios en el tamaño: el nombre nuevo es igual al anterior',
							color: 'info',
							timeout: 4000
						})              
					}
					res = await this.$store.dispatch('genericosServ/nuevoEditarTam', { nombre: this.tamanio.nombre, abreviacion: this.tamanio.abreviacion, nuevo: this.p_nuevo})
					if(res.resultado == 0){
						this.load = false
						return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error'})
					}
					this.dialog = false
					// emite la accion definifa en el padre
					let cad = ''
					if(this.p_nuevo){
						this.$emit('nuevo', res.tamanio)
						cad = 'Tamaño creado correctamente';
					}else {
						this.$emit('editar', this.tamanio);
						cad = 'Tamaño actualizado correctamente';
					}
					// muestra mensaje
					this.$store.dispatch('show_snackbar', { text: cad, color: 'success'	});
					this.load = false
        }
      }
    }
  }
  </script>